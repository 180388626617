import { useEffect, useState, useRef } from 'react'
import { trackClick } from '@tofu/shared/utils/analytics'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Carousel } from '@tofu/shared/ui/atoms/carousel'
import { useStaticContent } from '@tofu/shop/hooks/use-static-content'
import {
  carouselProductsFilterList,
  TCarouselProductsFilterList,
  TCarouselProduct
} from '@tofu/shop/utils/build-carousel-products'

import { responsiveCarouselSettings } from '../slice-product-carousel.constants'
import { SliceProductCarouselSliderFilters } from '../slice-product-carousel-slider-filters'
import { SliceProductCarouselItem } from '../slice-product-carousel-item'
import { TSliceProductCarouselSliderDefault } from './slice-product-carousel-slider-default.types'

export const SliceProductCarouselSliderDefault: TSliceProductCarouselSliderDefault =
  ({ display_navigation, display_add_buttons, onClickAdd }) => {
    const [activeCategory, setActiveCategory] =
      useState<TCarouselProductsFilterList>(carouselProductsFilterList[0])
    const sliderRef = useRef()
    const { carouselProducts } = useStaticContent()
    const productsForActiveCategory = carouselProducts[activeCategory]

    useEffect(() => {
      if (sliderRef.current) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        sliderRef?.current?.goToSlide(0)
      }
    }, [activeCategory])

    const handleOnFilterClick = (category: string) => {
      setActiveCategory(category as TCarouselProductsFilterList)
      trackClick('product.carousel.filter', { label: category })
    }

    return (
      <div data-testid='product-carousel-default'>
        {display_navigation && (
          <SliceProductCarouselSliderFilters
            activeFilter={activeCategory}
            filtersList={carouselProductsFilterList as unknown as string[]}
            handleClick={handleOnFilterClick}
          />
        )}
        {productsForActiveCategory?.length > 0 && (
          <Box
            sx={{
              '.react-multi-carousel-arrow ': {
                marginTop: '-140px'
              },
              '.react-multi-carousel-arrow-right': {
                right: '0'
              },
              '.react-multi-carousel-arrow-left': {
                left: '0'
              }
            }}
          >
            <Carousel
              ref={sliderRef}
              arrows
              centerMode={false}
              showDots={true}
              renderDotsOutside={true}
              slidesToSlide={1}
              swipeable
              infinite={true}
              responsive={responsiveCarouselSettings}
            >
              {productsForActiveCategory.map((product: TCarouselProduct) => (
                <SliceProductCarouselItem
                  key={product.handle}
                  onClickAdd={onClickAdd}
                  display_add_buttons={display_add_buttons}
                  {...product}
                />
              ))}
            </Carousel>
          </Box>
        )}
      </div>
    )
  }
