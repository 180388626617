/* istanbul ignore file */
import Image from 'next/image'
import { getChefDetails } from '@tofu/shared/utils/resident-chef'
import { VStack, HStack } from '@tofu/shared/ui/atoms/stack'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Button } from '@tofu/shared/ui/atoms/button'
import { PercentBadge } from '@tofu/shared/ui/atoms/icons'
import {
  ProductCardTagNew,
  getSpiceTagNameByNumber
} from '@tofu/shared/ui/molecules/product-card-tag-new'
import { ResidentChefBanner } from '@tofu/shared/ui/molecules/resident-chef-banner'
import { formatPrice } from '@tofu/shared/utils/format-currency'
import { Link } from '@tofu/shop/ui/atoms/link'

import greatTasteImage from './great_taste@2x.png'
import { TSliceProductCarouselItem } from './slice-product-carousel-item.types'

export const SliceProductCarouselItem: TSliceProductCarouselItem = ({
  display_add_buttons,
  handle,
  image_url,
  title,
  onClickAdd,
  display_award_badge,
  variants,
  resident_chef,
  is_new,
  spice_level
}) => {
  if (!handle) return null
  const residentChef = getChefDetails(resident_chef || '')
  const spiceTagName = spice_level && getSpiceTagNameByNumber(spice_level)

  /* istanbul ignore next */
  const {
    price: singlePrice,
    discounted_percentage,
    original_price
  } = variants?.['SINGLE_PORTION'] || {}

  return (
    <Flex
      data-testid={`carousel-item-${handle}`}
      flexDirection='column'
      minHeight={'100%'}
      mx={2}
      justifyContent='space-between'
    >
      <Box position='relative'>
        {display_award_badge ? (
          <Box
            position='absolute'
            top={0}
            left={0}
            m={2}
            w='56px'
            h='56px'
            zIndex={1}
          >
            <Image
              alt='Great Taste Award badge'
              layout='fill'
              src={greatTasteImage}
              width='100%'
              height='100%'
            />
          </Box>
        ) : null}
        {resident_chef && (
          <ResidentChefBanner size='sm' left={2} position='absolute' />
        )}
        <Box position='relative'>
          {image_url && (
            <Image
              alt=''
              data-testid='carousel-image'
              height={450}
              quality={30}
              src={image_url}
              width={450}
              objectFit='cover'
            />
          )}
          <Box position='absolute' bottom='15px' zIndex={1} right='10px'>
            <HStack width='100%' alignItems='center'>
              {is_new ? <ProductCardTagNew type='new' /> : null}
              {spiceTagName ? <ProductCardTagNew type={spiceTagName} /> : null}
            </HStack>
          </Box>
        </Box>
        <Flex direction='column'>
          {title && (
            <Text as='span' fontWeight='bold' id='carousel-title' mb={1}>
              {title}
            </Text>
          )}
          {residentChef && (
            <Box>
              <Text as='span' fontSize='sm' mr={1} aria-hidden>
                by
              </Text>
              <Image
                src={residentChef.signatureImage}
                width='96px'
                height='18px'
                alt={`By ${residentChef.name}`}
              />
            </Box>
          )}
        </Flex>
      </Box>
      {variants && display_add_buttons && (
        <>
          {discounted_percentage ? (
            <Flex
              alignItems='center'
              fontSize='sm'
              color='strawberry'
              mx={2}
              fontWeight='medium'
            >
              <PercentBadge mr={1} />
              Limited time offer
            </Flex>
          ) : null}

          <Flex alignItems='center' justifyContent='space-between'>
            {discounted_percentage && original_price && singlePrice ? (
              <VStack fontWeight='book' spacing={0} alignItems='baseline'>
                <Text
                  as='span'
                  fontSize='xs'
                  fontWeight='book'
                  color='grey.700'
                >
                  from{' '}
                  <Text as='s' color='black'>
                    {formatPrice(original_price)}
                  </Text>
                </Text>
                <Text
                  as='span'
                  fontSize='md'
                  fontWeight='bold'
                  color='strawberry'
                >
                  {formatPrice(singlePrice)}
                </Text>
              </VStack>
            ) : (
              <Text as='span' mt={0}>
                {singlePrice
                  ? `From ${formatPrice(singlePrice)}`
                  : formatPrice(variants?.['DOUBLE_PORTION']?.price)}
              </Text>
            )}
            <Link
              href={`/products/${handle}`}
              passHref
              // jest dosen't like testing onClicks on this Link, will come back to this
              /* istanbul ignore next */
              onClick={() => onClickAdd(handle)}
            >
              <Button
                aria-labelledby='carousel-add carousel-title'
                id='carousel-add'
                variant='solid'
                colorScheme='blackAlpha'
              >
                View
              </Button>
            </Link>
          </Flex>
        </>
      )}
    </Flex>
  )
}
