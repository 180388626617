export const RESIDENT_CHEFS_MAP = new Map([
  [
    'rachel_ama',
    {
      name: 'Rachel Ama',
      portraitImage: '/img/rachel-ama-portrait.jpg',
      signatureImage: '/img/rachel-ama-signature.png'
    }
  ]
])
