import { Flex } from '@tofu/shared/ui/atoms/flex'
import { Text } from '@tofu/shared/ui/atoms/text'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { TResidentChefBanner } from './resident-chef-banner.types'
import {
  CONTAINER_STYLES,
  TEXT_FIRST_LINE_STYLES,
  TEXT_SECOND_LINE_STYLES
} from './resident-chef-banner.constants'

export const ResidentChefBanner: TResidentChefBanner = ({
  size = 'md',
  ...flexProps
}) => {
  return (
    <Flex
      zIndex={1}
      alignItems='end'
      backgroundColor='brandYellow'
      {...CONTAINER_STYLES[size]}
      {...flexProps}
    >
      <VStack as='p' width='full' spacing={-1}>
        <Text
          as='span'
          fontFamily='heading'
          textTransform='uppercase'
          fontWeight='black'
          {...TEXT_FIRST_LINE_STYLES[size]}
        >
          Chef in
        </Text>
        <Text
          as='span'
          fontFamily='heading'
          textTransform='uppercase'
          letterSpacing='0.1px'
          {...TEXT_SECOND_LINE_STYLES[size]}
        >
          residence
        </Text>
      </VStack>
    </Flex>
  )
}
