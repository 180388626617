/* istanbul ignore file */
import Image from 'next/image'
import { Tag } from '@tofu/shared/ui/atoms/tag'
import { Text } from '@tofu/shared/ui/atoms/text'
import { TGetProductCardTagNew } from './product-card-tag-new.types'

const typeMap = new Map([
  [
    'new',
    <Tag
      variant='transparent'
      color='green.500'
      role='note'
      bg='white'
      height='100%'
      minHeight='100%'
      fontSize='12px'
      padding='2px 6px'
    >
      NEW
    </Tag>
  ],
  [
    'spice-medium',
    <Tag
      variant='transparent'
      color='orange.300'
      role='note'
      bg='white'
      height='100%'
      minHeight='100%'
      fontSize='12px'
      padding='2px 6px'
    >
      <Image src='/img/icon/spice-medium.png' width={12} height={12} alt='' />
      <Text ml={1}>Medium</Text>
    </Tag>
  ],
  [
    'spice-spicy',
    <Tag
      variant='transparent'
      color='pumpkin'
      role='note'
      bg='white'
      height='100%'
      minHeight='100%'
      fontSize='12px'
      padding='2px 6px'
    >
      <Image src='/img/icon/spice-spicy.png' width={12} height={12} alt='' />
      <Text ml={1}>Spicy</Text>
    </Tag>
  ]
])

export const getTypeTag: TGetProductCardTagNew = (type: string) =>
  typeMap.get(type) || null

export const getSpiceTagNameByNumber = (spice: number) => {
  switch (spice) {
    case 2:
      return 'spice-medium'
    case 3:
      return 'spice-spicy'
    default:
      return null
  }
}
