export const CONTAINER_STYLES = {
  sm: {
    width: '56px',
    height: '66px',
    padding: '3px'
  },
  md: {
    width: '80px',
    height: '86px',
    padding: '9px'
  }
}

export const TEXT_FIRST_LINE_STYLES = {
  sm: {
    fontSize: '18px',
    lineHeight: 1.1
  },
  md: {
    fontSize: '22px',
    lineHeight: 1.3
  }
}

export const TEXT_SECOND_LINE_STYLES = {
  sm: {
    fontSize: '12px',
    fontWeight: 'medium'
  },
  md: {
    fontSize: '14.8px',
    fontWeight: 'bold',
    lineHeight: 1
  }
}
